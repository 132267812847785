import React, { useRef, useState } from 'react';
import { Icon } from '@fluentui/react';
import { Avatar, Button, EditIcon, Input, Tooltip, TrashCanIcon } from '@fluentui/react-northstar';
import { Draggable } from 'react-beautiful-dnd';
import { DateTime } from 'luxon';
import { useIntl as useReactIntl } from 'react-intl';
import { useIntl } from 'app/i18n';
import {
  BottomPopupItems,
  allowGoToResultsOnClickPanelCard,
  disallowGoToResultsOnClickPanelCard,
  getFilterLocalizationKey,
  groupFiltersByFieldName,
  hideActiveClassOnPanelCard,
  hideCardPanelEditInput,
  hideEditAndDeleteIconsOnPanelCards,
  openBottomPopup,
  selectIsBottomPopupVisible,
  selectIsEditedSavedSearchPanelCard,
  selectIsOnTouch,
  selectIsPanelCardEditing,
  selectPanelCardActiveClass,
  selectSavedSearchCardPanelKey,
  showActiveClassOnPanelCard,
  showAlertNotification,
  showCardPanelEditInput,
  showEditAndDeleteIconsOnPanelCards,
  useAppDispatch,
  useAppSelector,
  useReferenceListLocalization
} from 'app/common';
import { SavedSearch } from 'app/pages/my-audience/saved-searches';
import { useOnClickOutside } from 'usehooks-ts';

export interface SavedSearchCardProps {
  savedSearch: SavedSearch;
  index: number;
  onUpdateSavedSearch: (updatedSavedSearch: SavedSearch) => void;
  onSetSavedSearchToRemove: (savedSearchToRemove: SavedSearch) => void;
  onSavedSearchClick: (savedSearch: SavedSearch) => void;
  onMouseEnter: () => void;
}

export const SavedSearchCard = ({
  savedSearch,
  index,
  onUpdateSavedSearch,
  onSetSavedSearchToRemove,
  onSavedSearchClick,
  onMouseEnter
}: SavedSearchCardProps) => {
  const { formatMessage } = useIntl();
  const { localizeFilterItem } = useReferenceListLocalization();
  const ref = useRef(null);
  const intl = useReactIntl();
  const dispatch = useAppDispatch();

  const savedSearchPanelCardKey = useAppSelector(selectSavedSearchCardPanelKey);
  const isPanelCardEditing = useAppSelector(selectIsPanelCardEditing);
  const isEditedPanelSavedSearchCard = useAppSelector(selectIsEditedSavedSearchPanelCard);
  const activeClass = useAppSelector(selectPanelCardActiveClass);
  const isOnTouch = useAppSelector(selectIsOnTouch);
  const isBottomPopupVisible = useAppSelector(selectIsBottomPopupVisible);
  const groupedFilterValues = groupFiltersByFieldName(savedSearch.filterItems);

  const [name, setName] = useState(savedSearch.name);

  const cancelEdit = () => {
    setName(savedSearch.name);
    handleDismissEditInput();
  };

  const handleCancelEditClick = (event: React.SyntheticEvent<HTMLElement, Event>) => {
    event.stopPropagation();
    cancelEdit();
  };

  const handleContactsNumber = (amountOfContacts: number): string => {
    const numberOfContactsToValidate = amountOfContacts - 7;
    const contactString = numberOfContactsToValidate.toString();

    return contactString.length <= 2 ? `+${contactString}` : `+${contactString.slice(0, 2)}...`;
  };

  const handleDismissEditInput = () => {
    if (isPanelCardEditing) {
      dispatch(hideCardPanelEditInput());
      dispatch(allowGoToResultsOnClickPanelCard());
    }
  };

  useOnClickOutside(ref, cancelEdit);

  const handleRemoveSavedSearchPanelCard = (e: React.SyntheticEvent<HTMLElement, Event>) => {
    e.stopPropagation();
    onSetSavedSearchToRemove(savedSearch);
  };

  const handleEditButtonClick = (e: React.SyntheticEvent<HTMLElement, Event>) => {
    e.stopPropagation();
    isPanelCardEditing && dispatch(disallowGoToResultsOnClickPanelCard());
    dispatch(showCardPanelEditInput(savedSearch.id));
  };

  const onClickMoreOptions = (event: React.SyntheticEvent<HTMLElement, Event>) => {
    event.stopPropagation();
    dispatch(openBottomPopup(bottomPopupItems));
  };

  const handleOnMouseEnter = () => {
    onMouseEnter();
    dispatch(showEditAndDeleteIconsOnPanelCards(savedSearch.id));
    isPanelCardEditing && dispatch(hideEditAndDeleteIconsOnPanelCards(savedSearch.id));
  };

  const handleOnMouseLeave = () => {
    setName(savedSearch.name);
    dispatch(hideEditAndDeleteIconsOnPanelCards(savedSearch.id));
    isPanelCardEditing && dispatch(hideCardPanelEditInput());
  };

  const handleEditing = (event) => {
    event.stopPropagation();

    onUpdateSavedSearch(
      new SavedSearch(
        savedSearch.id,
        name,
        savedSearch.searchText,
        savedSearch.filterItems,
        savedSearch.priority,
        savedSearch.count,
        savedSearch.createdAt,
        DateTime.utc().toJSDate(),
        savedSearch.createdById
      )
    );
    dispatch(showActiveClassOnPanelCard());
    dispatch(showAlertNotification(formatMessage({ id: 'information-messages.changes-saved' })));
    handleDismissEditInput();

    const timer = setTimeout(() => {
      dispatch(hideActiveClassOnPanelCard());
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
  };

  const handleEditingSavedSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      handleEditing(event);
    } else if (event.key === 'Escape') {
      cancelEdit();
    }
  };

  const onCardClick = (savedSearch: SavedSearch) => {
    onSavedSearchClick(savedSearch);
  };

  const handleOnClick = () => {
    !isPanelCardEditing && onCardClick(savedSearch);
    isOnTouch && !isBottomPopupVisible && onCardClick(savedSearch);
  };

  const handleOnInputClick = (event) => {
    event.stopPropagation();
  };

  const bottomPopupItems: BottomPopupItems[] = [
    {
      key: 'edit-saved-search-card',
      title: formatMessage({ id: 'buttons.edit' }),
      className: 'bottom-popup-items',
      onClick: handleEditButtonClick,
      icon: <Icon iconName="Edit" />
    },
    {
      key: 'delete-saved-search-card',
      title: formatMessage({ id: 'buttons.delete' }),
      className: 'bottom-popup-items',
      onClick: handleRemoveSavedSearchPanelCard,
      icon: <Icon iconName="Delete" />
    }
  ];

  return (
    <div onMouseEnter={handleOnMouseEnter} onMouseLeave={handleOnMouseLeave} className="panel-card-container">
      <Draggable key={savedSearch.id} draggableId={savedSearch.id} index={index}>
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            onClick={handleOnClick}
            className={isEditedPanelSavedSearchCard && savedSearch.id === savedSearchPanelCardKey ? `panel-card ${activeClass} ` : 'panel-card'}
          >
            {isPanelCardEditing && savedSearch.id === savedSearchPanelCardKey ? (
              <div className="card-input" ref={ref}>
                <Input
                  value={name}
                  onChange={(e) => setName((e.target as HTMLTextAreaElement).value)}
                  onKeyDown={handleEditingSavedSearch}
                  onClick={handleOnInputClick}
                  icon={
                    <div className="card-input-actions">
                      <Button onClick={handleEditing} iconOnly content={<Icon iconName="CheckMark" />} text />
                      <Button onClick={handleCancelEditClick} iconOnly content={<Icon iconName="Cancel" />} text />
                    </div>
                  }
                />
              </div>
            ) : (
              <div className="panel-card-body">
                <div className="card-text__wrap">
                  <span>{savedSearch.name}</span>
                  {Object.keys(groupedFilterValues).map((key) => (
                    <span key={key}>
                      <span className="card-text">
                        {intl.formatMessage({ id: getFilterLocalizationKey(groupedFilterValues[key][0].fieldName) || 'filters.filters' })}:{' '}
                      </span>
                      {groupedFilterValues[key].map((filterItem) => (
                        <span key={filterItem.fieldName}>
                          &quot;{localizeFilterItem(filterItem)}
                          &quot;
                        </span>
                      ))}
                    </span>
                  ))}
                </div>
                <div className="card-avatars">
                  {savedSearch?.avatarDetails &&
                    savedSearch?.avatarDetails.slice(0, 7).map((item, index) => (
                      <Tooltip
                        key={index}
                        subtle={false}
                        pointing
                        trigger={
                          <Avatar
                            key={index}
                            name={item.name}
                            image={item.profilePictureUrl}
                            size="smaller"
                            label={{
                              variables: {
                                backgroundColor: 'blue'
                              }
                            }}
                          />
                        }
                        content={item.name}
                        position="below"
                        align="center"
                      />
                    ))}
                  {savedSearch?.avatarDetails && savedSearch?.resultsLength - 7 > 0 && (
                    <Tooltip
                      key="saved-search-card-panel-users"
                      subtle={false}
                      pointing
                      trigger={
                        <Avatar
                          getInitials={() => handleContactsNumber(savedSearch?.resultsLength)}
                          size="smaller"
                          label={{
                            variables: {
                              backgroundColor: 'blue'
                            }
                          }}
                        />
                      }
                      content={savedSearch?.resultsLength - 7 + ' more'}
                      position="below"
                      align="center"
                    />
                  )}
                </div>
              </div>
            )}
            {isOnTouch && (
              <div className="bottom-popup-icon-wrap">
                <Button className="bottom-popup-icon" onClick={(event) => onClickMoreOptions(event)}>
                  <Icon iconName="MoreVertical" />
                </Button>
              </div>
            )}
            {!isPanelCardEditing && !isOnTouch && (
              <div className="card-icons">
                <div className="card-edit-wrap">
                  <Tooltip
                    key="saved-search-card-panel-edit"
                    subtle={false}
                    pointing
                    trigger={<Button icon={<EditIcon size="small" />} onClick={handleEditButtonClick} iconOnly text />}
                    content={formatMessage({ id: 'buttons.edit' })}
                    position="below"
                    align="center"
                  />
                </div>
                <div className="card-delete-wrap">
                  <Tooltip
                    key="saved-search-card-panel-delete"
                    subtle={false}
                    pointing
                    trigger={<Button onClick={handleRemoveSavedSearchPanelCard} icon={<TrashCanIcon size="small" />} iconOnly text />}
                    content={formatMessage({ id: 'buttons.delete' })}
                    position="below"
                    align="center"
                  />
                </div>
              </div>
            )}
          </div>
        )}
      </Draggable>
    </div>
  );
};
